import { createSlice } from '@reduxjs/toolkit';

const CKEditorSlice = createSlice({
  name: 'ckeditor',
  initialState: {
    content: '',
    ckButton: false,
    fields: [],
    editorPageSize: '',
    autoSave: false,
    editor: {},
    pdfPage: 1,
    enableDownload: true,
    borderAndMargin: {},
    storedCoordinates: [],
    stampBase64: [],
    blockStampPaper: false,
    pageOrientation: 'Portrait'
  },
  reducers: {
    updateContent: (state, action) => {
      state.content = action.payload;
    },
    updateFields: (state, action) => {
      state.fields = action.payload;
    },
    addPageSize: (state, action) => {
      state.editorPageSize = action.payload;
    },
    setEditorSlice: (state, action) => {
      state.editor = action.payload;
    },
    updateCkbuttonStatus: (state, action) => {
      state.ckButton = action.payload;
    },
    setPdfPage: (state, action) => {
      state.pdfPage = action.payload;
    },
    setDownload: (state, action) => {
      state.enableDownload = action.payload;
    },
    setAutoSave: (state, action) => {
      state.autoSave = action.payload;
    },
    setTemplateBorderAndMargin: (state, action) => {
      state.borderAndMargin = action.payload;
    },
    setStoredCoord: (state, action) => {
      state.storedCoordinates = action.payload;
    },
    setStampBase64: (state, action) => {
      state.stampBase64 = [...state.stampBase64, ...action.payload];
    },
    clearStampBase64: (state, action) => {
      state.stampBase64 = action.payload;
    },
    setBlockStampPaper: (state, action) => {
      state.blockStampPaper = action.payload;
    },
    setPageOrientation: (state, action) => {
      state.pageOrientation = action.payload;
    }
  }
});

export const {
  updateContent,
  updateCkbuttonStatus,
  updateFields,
  addPageSize,
  setEditorSlice,
  setAutoSave,
  setPdfPage,
  setDownload,
  setTemplateBorderAndMargin,
  setStoredCoord,
  setStampBase64,
  clearStampBase64,
  setBlockStampPaper,
  setPageOrientation
} = CKEditorSlice.actions;

export default CKEditorSlice.reducer;
