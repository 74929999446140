import React from 'react';
import { RouterProvider } from 'react-router';
import Router from './Routes/Router';
import { Provider } from 'react-redux';
import { store } from './Store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
function App() {
  return (
    <div className='App'>
      <Provider store={store}>
        <ToastContainer />
        <RouterProvider router={Router} />
      </Provider>
    </div>
  );
}
export default App;
