import { createSlice } from '@reduxjs/toolkit';

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    notificationStatus: false,
    hiddenNotifications: [],
    filteredData: [],
    digitalStampOrderStatus: false,
    onSuccesApiCall: false,
    esignStatus: false
  },
  reducers: {
    checkNotificationStatus: (state, action) => {
      state.notificationStatus = action.payload;
    },
    updateHiddenNotifications: (state, action) => {
      state.hiddenNotifications = action.payload;
    },
    updateFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    updateDigitalStampStatus: (state, action) => {
      state.digitalStampOrderStatus = action.payload;
    },
    updateSuccessStatus: (state, action) => {
      state.onSuccesApiCall = action.payload;
    },
    updateEsignStatus: (state, action) => {
      state.esignStatus = action.payload;
    }
  }
});

export const {
  checkNotificationStatus,
  updateHiddenNotifications,
  updateFilteredData,
  updateDigitalStampStatus,
  updateSuccessStatus,
  updateEsignStatus
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
