import { resetState } from '../Redux/FileListSlice';

const resetStateMiddleware = store => next => action => {
  if (action.type === '@@redux/INIT') {
    console.log('Dispatching resetState action');
    store.dispatch(resetState());
  }
  return next(action);
};

export default resetStateMiddleware;
