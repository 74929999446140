import { createSlice } from '@reduxjs/toolkit';

const initialState = { content: '' };

const DocumentSlice = createSlice({
  name: 'ckEditor',
  initialState,
  reducers: {
    updateContent: (state, action) => {
      state.content = action.payload;
    }
  }
});

export const { updateContent } = DocumentSlice.actions;
export default DocumentSlice.reducer;
