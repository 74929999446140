import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  userInfo: {},
  userSetting: {},
  userToken: null,
  error: null,
  success: false,
  accessToken: '',
  inProgress: { value: false, data: {} },
  accessId: '',
  mobileNo: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
    updateUserSetting: (state, action) => {
      state.userSetting = action.payload;
    },
    updateStatus: (state, action) => {
      state.inProgress = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    resetAuth: () => initialState,
    updateAccessId: (state, action) => {
      state.accessId = action.payload;
    },
    updateMobileNo: (state, action) => {
      state.mobileNo = action.payload;
    }
  }
  // extraReducers: {}
});

export const {
  updateUserInfo,
  updateUserSetting,
  resetAuth,
  updateStatus,
  setAccessToken,
  updateAccessId,
  updateMobileNo
} = authSlice.actions;
export default authSlice.reducer;
