import { createSlice } from '@reduxjs/toolkit';

// {
//   id: '',
//   userData: { recipientId: '', email: '', mobileno: '', firstname: '', lastname: '' }
// }
const RecipientsSlice = createSlice({
  name: 'recipients',
  initialState: {
    data: {
      id: '',
      refNo: '',
      documentName: '',
      enableOtp: false,
      getLocation: false,
      getPicture: false,
      recipientsList: []
    }
  },
  reducers: {
    addRecipient: (state, action) => {
      state.data.recipientsList = action.payload;
    },
    updateRecipient: (state, action) => {
      state = state.data.recipientsList.map(el =>
        el.id === action.payload.id ? { ...el, userData: action.payload.userData } : el
      );
    },
    updateRecipientOtp: (state, action) => {
      const fileToUpdate = state.data.recipientsList.find(el => el.id === action.payload.id);

      if (fileToUpdate) {
        fileToUpdate.enableOtp = action.payload.enableOtp;
        fileToUpdate.getLocation = action.payload.getLocation;
        fileToUpdate.getPicture = action.payload.getPicture;
      }
    },
    addrecipientOtp: (state, action) => {
      state.data.enableOtp = action.payload;
    },
    deleteRecipient: (state, action) => {
      state = state.data.recipientsList.filter(el => el.recipientId !== action.payload.recipientId);
    },
    clearRecipient: (state, action) => {
      state.data.recipientsList = action.payload;
    },
    setLocationWhileSign: (state, action) => {
      state.data.getLocation = action.payload;
    },
    setPictureWhileSign: (state, action) => {
      state.data.getPicture = action.payload;
    }
  }
});
export const {
  addRecipient,
  updateRecipient,
  clearRecipient,
  addrecipientOtp,
  setLocationWhileSign,
  setPictureWhileSign,
  updateRecipientOtp
} = RecipientsSlice.actions;
export default RecipientsSlice.reducer;
