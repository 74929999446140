import { createSlice } from '@reduxjs/toolkit';
const EstampSlice = createSlice({
  name: 'estamp',
  initialState: { estampData: null, digitalStampData: null },
  reducers: {
    updateEstampData: (state, action) => {
      state.estampData = action.payload;
    },
    updateDigitalStampData: (state, action) => {
      state.digitalStampData = action.payload;
    }
  }
});

export const { updateEstampData, updateDigitalStampData } = EstampSlice.actions;

export default EstampSlice.reducer;
