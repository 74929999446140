import { createSlice } from '@reduxjs/toolkit';
const WalletSlice = createSlice({
  name: 'wallet',
  initialState: { walletBalanceDetails: null },
  reducers: {
    updateWalletBalance: (state, action) => {
      state.walletBalanceDetails = action.payload;
    }
  }
});

export const { updateWalletBalance } = WalletSlice.actions;

export default WalletSlice.reducer;
