import { createSlice } from '@reduxjs/toolkit';
const NewESignSlice = createSlice({
  name: 'newesign',
  initialState: {
    documentID: null,
    signLocationVal: null,
    stampValue: null,
    sequentialSign: null,
    newEsignData: {
      filename: null,
      reference: null
    }
  },
  reducers: {
    updateNewESignData: (state, action) => {
      state.newEsignData = { ...state.newEsignData, ...action.payload };
    },
    updateDocumentID: (state, action) => {
      state.documentID = action.payload;
    },
    updateSignLocation: (state, action) => {
      state.signLocationVal = action.payload;
    },
    updateStampValue: (state, action) => {
      state.stampValue = action.payload;
    },
    updateSequentialSign: (state, action) => {
      state.sequentialSign = action.payload;
    }
  }
});

export const { updateNewESignData, updateDocumentID, updateSignLocation, updateStampValue, updateSequentialSign } =
  NewESignSlice.actions;

export default NewESignSlice.reducer;
