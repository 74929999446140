import { combineReducers, configureStore } from '@reduxjs/toolkit';
import DocumentRedux from './DocumentSlice/DocumentSlice';
import authReducer from './AuthSlice/AuthSlice';
import FileListSlice from './Redux/FileListSlice';
import CKEditorSlice from './Redux/CKEditorSlice';
import RecipientsSlice from './RecipientsSlice.js/RecipientsSlice';
import Logger from './Logger';
import thunk from 'redux-thunk';
import EstampSlice from './Redux/EstampSlice';
import NewESignSlice from './Redux/NewESignSlice';
import DashboardSlice from './DashboardSlice/DashboardSlice';
import WalletSlice from './Redux/WalletSlice';
import resetStateMiddleware from './Middleware/ResetState';

const reducer = combineReducers({
  documentData: DocumentRedux,
  auth: authReducer,
  ckeditor: CKEditorSlice,
  fileList: FileListSlice,
  recipients: RecipientsSlice,
  estamp: EstampSlice,
  newesign: NewESignSlice,
  dashboard: DashboardSlice,
  wallet: WalletSlice
});

export const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(Logger, thunk, resetStateMiddleware),
  devTools: process.env.REACT_APP_ENV === 'local'
});
