import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filesList: [],
  attachedFiles: [],
  selectedStamp: '',
  selectedFileStatus: null,
  holdTemplateName: '',
  savedfile: {},
  selectedFile: null,
  ckAttach: [],
  esignAttach: [],
  previewData: '',
  selPdf: {},
  singedFile: [],
  singedInputFields: null,
  inputValues: [],
  pdfLoader: true,
  selectedFormType: [],
  stampFormState: {},
  signCoordinates: {},
  recipientFields: [],
  stampConfig: {
    fileId: '',
    isRecipientToPayStamp: false,
    data: []
  },
  recpInput: [],
  defaultPdfSign: {
    enabled: false,
    coord: ''
  },
  additionalCharges: {},
  isAdditionalAmountRequired: null,
  newBase64: null
};

const fileListSlice = createSlice({
  name: 'fileList',
  initialState,
  reducers: {
    addFile: (state, action) => {
      state.filesList.push(action.payload);
    },

    selectFile: (state, action) => {
      if (action.payload?.type && action.payload?.type === 'pdf') {
        // Dispatch a different action for PDF files
        //replace filedata with action.payload as filedata dint had type
        if (!state.selectedFile) {
          state.selectedFile = [action.payload];
        } else if (!Array.isArray(state.selectedFile)) {
          state.selectedFile = [state.selectedFile, action.payload];
        } else {
          state.selectedFile.push(action.payload);
        }
      } else {
        // Dispatch a different action for other file types
        state.selectedFile = action.payload;
      }
    },
    resetSelectFile: (state, action) => {
      const idToRemove = action.payload;

      if (Array.isArray(state.selectedFile)) {
        const updatedSelectedFile = state.selectedFile.filter(file => file.id !== idToRemove);

        state.selectedFile = updatedSelectedFile.length > 0 ? updatedSelectedFile : null;
      } else {
        state.selectedFile = null;
      }
    },
    updateSelectedFileName: (state, action) => {
      state.selectedFile = action.payload;
    },
    updatedSelectFile: (state, action) => {
      state.selectedFile.base64pdf = action.payload;
    },
    setSignCoordiantes: (state, action) => {
      state.signCoordinates = action.payload;
    },
    saveFile: (state, action) => {
      const { name, content, thumbnail, fields, dropdownFields } = action.payload;

      state.savedfile = { id: Date.now(), name, content, thumbnail, fields, dropdownFields };
    },
    setsingedFile: (state, action) => {
      state.singedFile = action.payload;
    },
    clearsingedFile: state => {
      state.singedFile = [];
    },
    setSingedFields: (state, action) => {
      //for storing singed input fields with values so that is dones't get removed
      state.singedInputFields = action.payload;
    },
    setRecipientFields: (state, action) => {
      state.recipientFields = action.payload;
    },
    setFileds: (state, action) => {
      state.recpInput = action.payload;
    },
    setPdfloader: (state, action) => {
      state.pdfLoader = action.payload;
    },
    selectPdf: (state, action) => {
      state.selPdf = action.payload;
    },
    removeFile: (state, action) => {
      // Remove a file from the list by index
      const indexToRemove = action.payload;
      state.filesList.splice(indexToRemove, 1);
    },
    addAttachedFile: (state, action) => {
      // Add a new file to the list
      const { id, name, content, dataUrl } = action.payload;
      state.attachedFiles.push({ id, name, content, dataUrl }); // Add a unique ID for each file
    },
    removeAttachedFile: (state, action) => {
      // Remove the file with the specified ID
      const idToRemove = action.payload;
      state.attachedFiles = state.attachedFiles.filter(file => file.id !== idToRemove);
    },
    removeSignedFile: (state, action) => {
      const idToRemove = action.payload;

      if (state.singedFile?.length > 0) {
        state.singedFile = state.singedFile?.filter(file => file.id !== idToRemove);
      }
    },
    removeCkAttachedFile: (state, action) => {
      // Remove the file with the specified ID
      const idToRemove = action.payload;
      state.ckAttach = state.ckAttach.filter(file => file.id !== idToRemove);
    },
    removeEsignAttachedFile: (state, action) => {
      // Remove the file with the specified ID
      const idToRemove = action.payload;
      state.esignAttach = state.esignAttach.filter(file => file.id !== idToRemove);
    },
    resetallAttachedFile: state => {
      return { ...state, attachedFiles: [] };
    },
    updateFileName: (state, action) => {
      // Update the name of a file in the list by index
      const { index, name } = action.payload;
      state.filesList[index].name = name;
    },
    updateFileContent: (state, action) => {
      const { id, content, fields, thumbnail, dropdownFields } = action.payload;
      const fileToUpdate = state.filesList.find(file => file.id === id);

      if (fileToUpdate) {
        fileToUpdate.content = content;
        fileToUpdate.fields = fields;
        fileToUpdate.thumbnail = thumbnail;
        fileToUpdate.dropdownFields = dropdownFields;
      }
    },
    setPreviewDataAction: (state, action) => {
      state.previewData = action.payload;
    },
    addCkAttachments: (state, action) => {
      state.ckAttach.push(action.payload);
    },
    addEsignAttachments: (state, action) => {
      state.esignAttach.push(action.payload);
    },
    removeAllEsignAttachments: (state, action) => {
      state.esignAttach = action.payload;
    },
    removedAllCkAttachments: (state, action) => {
      state.ckAttach = action.payload;
    },
    updateFileThumbnail: (state, action) => {
      const { id, thumbnail } = action.payload;
      const fileToUpdate = state.filesList.find(file => file.id === id);

      if (fileToUpdate) {
        fileToUpdate.thumbnail = thumbnail;
      }
    },
    updateFileBytes: (state, action) => {
      const { id, bytes, dataUrl } = action.payload;

      if (Array.isArray(state.selectedFile)) {
        const fileToUpdate = state.selectedFile.find(file => file.id === id);

        if (fileToUpdate) {
          fileToUpdate.bytes = bytes;
          fileToUpdate.dataUrl = dataUrl;
        }
      }
    },
    updateEsign: (state, action) => {
      const { esign } = action.payload;

      if (Array.isArray(state.selectedFile)) {
        state.selectedFile = state.selectedFile.map(file => ({ ...file, esign }));
      }
    },
    updateSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    updateSingedFile: (state, action) => {
      const { id, dataUrl } = action.payload;
      state.singedFile = state.singedFile.map(el =>
        el.id === id ? { ...el, dataUrl: dataUrl, newDataUrl: dataUrl } : el
      );
    },
    // Add this new reducer to update the selected stamp
    updateStamp: (state, action) => {
      state.selectedStamp = action.payload;
    },

    selecteFileStatus: (state, action) => {
      state.selectedFileStatus = action.payload;
    },

    holdTemplateNames: (state, action) => {
      state.holdTemplateName = action.payload;
    },
    updateFormType: (state, action) => {
      state.selectedFormType = action.payload;
    },
    updateState: (state, action) => {
      state.stampFormState = action.payload;
    },
    addCkStampConfig: (state, action) => {
      state.stampConfig = action.payload;
    },
    setDefaultPdfSign: (state, action) => {
      const { enabled, coord } = action.payload;
      state.defaultPdfSign = { enabled, coord };
    },
    setInputValues: (state, action) => {
      state.inputValues = action.payload;
    },
    updateSelectedFileById: (state, action) => {
      const { newBase64, pdfData } = action.payload;
      const findPdf = state.selectedFile.find(item => item.id === pdfData.id);
      state.newBase64 = newBase64;
      if (findPdf) {
        findPdf.newDataUrl = newBase64;
        findPdf.dataUrl = newBase64;
      }
    },
    addAdditionalCharges: (state, action) => {
      state.additionalCharges = action.payload;
    },
    recipientsToPay: (state, action) => {
      state.isAdditionalAmountRequired = action.payload;
    },
    resetState: () => initialState
  }
});

export const {
  addFile,
  saveFile,
  removeFile,
  addAttachedFile,
  removeAttachedFile,
  removeCkAttachedFile,
  removeEsignAttachedFile,
  resetallAttachedFile,
  updateFileName,
  updateFileContent,
  updateFileThumbnail, // Add the updateFileThumbnail action
  updateStamp,
  selecteFileStatus,
  holdTemplateNames,
  addCkAttachments,
  selectFile,
  setPreviewDataAction,
  resetSelectFile,
  updateFileBytes,
  removeSignedFile,
  updateEsign,
  setsingedFile,
  setSingedFields,
  selectPdf,
  clearsingedFile,
  updateSingedFile,
  setPdfloader,
  setSignCoordiantes,
  removedAllCkAttachments,
  updateFormType,
  setRecipientFields,
  addCkStampConfig,
  setFileds,
  addCkStampConfigAmount,
  updateState,
  addEsignAttachments,
  removeAllEsignAttachments,
  setDefaultPdfSign,
  setInputValues,
  updatedSelectFile,
  updateSelectedFile,
  updateSelectedFileName,
  updateSelectedFileById,
  addAdditionalCharges,
  recipientsToPay,
  resetState
} = fileListSlice.actions;

export default fileListSlice.reducer;
