import { createLogger } from 'redux-logger';

const loggerColors = {
  title: action => {
    return action.type === 'ERROR' ? 'red' : 'blue';
  },
  prevState: () => 'blue',
  action: () => 'grean',
  nextState: () => 'brown',
  error: () => 'red'
};

const Logger = createLogger({
  // Configuration options
  collapsed: true,
  duration: true,
  timestamp: true,
  level: 'log', // You can change this to 'info', 'warn', 'error', etc.
  colors: loggerColors,
  diff: true,
  // Define a predicate to selectively log actions
  predicate: (getState, action) => {
    return {getState, action} ;// Log all actions(Make true if you want to see all the logs in console)
  },
  // Define a state transformer function (optional)
  stateTransformer: state => {
    return state; // No transformation in this example
  },
  // Define an action transformer function (optional)
  actionTransformer: action => {
    return action; // No transformation in this example
  },
  // Define an error transformer function (optional)
  errorTransformer: error => {
    return error; // No transformation in this example
  }
});

export default Logger;
