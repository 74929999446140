import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
const UnProtectedLayout = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </Suspense>
  );
};

export default UnProtectedLayout;