import React, { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import UnProtectedLayout from '../Components/Layouts/UnProtectedLayout';
const LandingPage = lazy(() => import('../Components/Pages/LandingPage/LandingPage'));


export default createBrowserRouter([
   
    {
      element: <UnProtectedLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
       
      ]
    },
    {
      path: '*',
      element: (
        <Suspense fallback={<div>Loading...</div>}>
         <div>Not Found Page</div>
        </Suspense>
      )
    }
  ]);
  